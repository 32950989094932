import React from 'react';

export default function EmailComposerSelectGifType({ onSelect }) {
  return (
    <div className='app__button-container--large'>
    <div className='app__button-div--large'>
      <button onClick={onSelect('animated')} className='app__button app__button--large app__button app__button--animated-gif'></button>
         <span>ANIMATED GIF</span>
      </div>
      <div className='app__button-div--large'>
      <button onClick={onSelect('still')} className='app__button app__button--large app__button app__button--photo'></button>
         <span>PHOTO</span>
      </div>
    </div>
  );
}
