import React from 'react';
import useApiPost from '../api/useApiPost';
import config from 'react-global-configuration';

const buildFormData = ({ email, password }) => {
  let formData = new FormData();
  formData.set('hID', 'login');
  formData.set('email', email);
  formData.set('pwd', password);
  return formData;
};

export default function useLogin() {
  const { handlePost, loading } = useApiPost();

  const login = React.useCallback(
    ({ email, password, onSuccess = undefined, onError = undefined }) =>
      handlePost({
        url: config.get('apiUrl') + 'login.php',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: buildFormData({ email, password }),
        onSuccess: response => {
          if (response.data.status === 'false' || response.data.status === 'fail') {
            onError && onError(response);
            console.log('Error logging in');
          } else {
            onSuccess && onSuccess(response);
          }
        },
        onError: response => {
          onError && onError(response);
          console.error('Error logging in', response);
        }
      }),
    [handlePost]
  );

  return { login, loading };
}
