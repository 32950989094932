import React from 'react';
import Feature from '../Feature';
import { isEmpty } from '../../utils/helpers';
import useUser from '../../store/selectors/useUser';
import { Link, useLocation } from 'react-router-dom';

const tabs = [
  { label: 'Send Email', route: '/', altRoute: '/SendEmail' },
  { label: 'Video', route: '/Videos' },
  { label: 'Attachments', route: '/Attachments' },
  { label: 'Activity', route: '/Activity' },
  { label: 'Admin', route: '/Admin', admin: true }
];

export default function Header() {
  const { pathname } = useLocation();
  const { isAdmin } = useUser();

  return (
    <header className='app__header clearfix'>
      <div className='app__header__container'>
        <div className='app__header__profile'></div>
        <div className='app__header__tabs'>
          {tabs.map(({ label, route, altRoute, admin }) => {
            const isActive = pathname === route || (!isEmpty(altRoute) && pathname === altRoute);

            if(isAdmin !== '1' && admin ) {
              return;
            } else {
              return (
                <Feature name={label} key={label}>
                  <div>
                    <Link to={`${route}`} className={`app__header__tab${isActive ? ' active' : ''}`}>
                      {label}
                    </Link>
                  </div>
                </Feature>
              );
            }
          })}
        </div>
      </div>
    </header>
  );
}