const Feature = ({name, children}) => {
    const features = JSON.parse(localStorage.getItem('flags'));
    const feature = features.find(feature => feature.name === name.toLowerCase());
    
    if(feature === undefined) {
        return children;
    } else if (feature && feature.active === '1') {
        return children;
    }

    return null;
};

export default Feature;