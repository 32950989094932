import React from 'react';
import { isEmpty, yyyymmdd } from '../utils/helpers';
import { useHandleChange } from '../utils/useOnChange';
import TimeZone from './input/TimeZone';

const validate = values => {
  const errors = {};
  ['dateTime', 'timeZone'].forEach(key => {
    if (isEmpty(values[key])) errors[key] = `Required (${key})`;
  });
  return errors;
};

export default function SendEmailButtons({ onBack, onNext, onSendEmail, onScheduleEmail, disabled }) {
  const [{ errors = {}, dateTime, timeZone, showScheduleOptions }, setState] = React.useState({
    timeZone: `US/Eastern`,
    showScheduleOptions: false,
    dateTime: yyyymmdd(new Date(), 1) + 'T09:00'
  });

  const handleChange = useHandleChange(setState);
  const handleSchedule = showScheduleOptions => () => setState(prev => ({ ...prev, showScheduleOptions }));

  const handleScheduleEmail = React.useCallback(() => {
    const errors = validate({ dateTime, timeZone });
    if (Object.keys(errors).length > 0) {
      setState(prev => ({ ...prev, errors }));
    } else {
      onScheduleEmail && onScheduleEmail({ dateTime, timeZone });
    }
  }, [dateTime, timeZone, onScheduleEmail, setState]);

  const handleSendEmail = React.useCallback(() => onSendEmail(), [onSendEmail]);

  return (
    <>
      <div className='app__subheader__email__buttons'>
        <button className='app__back-to-edit__button app__button' onClick={onBack}>
          Back to Edit
        </button>
        <button className={`app__send-email__button app__button${disabled ? ' disabled' : ''}`} onClick={handleSendEmail} disabled={disabled ? 'disabled' : ''}>
          Send Now
        </button>
        <button className='app__send-email__button app__button' onClick={handleSchedule(!showScheduleOptions)}>
          Send Later
        </button>
      </div>
      {showScheduleOptions && (
        <div className='app__subheader__email__schedule'>
          <input className="app__small-input" type='datetime-local' value={dateTime} onChange={handleChange('dateTime')} />
          <TimeZone value={timeZone} onChange={handleChange('timeZone')} />
          <button
            className={`app__send-email__button app__button${disabled ? ' disabled' : ''}`}
            onClick={handleScheduleEmail}
            disabled={disabled ? 'disabled' : ''}
          >
            Schedule
          </button>
          <button className='app__close-schedule__button app__button' onClick={handleSchedule(false)}>
            <i className="fa fa-close"></i>
          </button>
          {Object.keys(errors).map(k => (
            <div id={k} key={k} style={{ color: 'red', paddingTop: 8, paddingBottom: 8 }}>
              {errors[k]}
              <br />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
