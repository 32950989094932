import React from "react";
import { useDispatch } from "react-redux";
import { updateSelectedTemplateId } from ".";

export default function useUpdateSelectedTemplateId() {
  const dispatch = useDispatch();
  return React.useCallback(
    (id) => dispatch(updateSelectedTemplateId(id)),
    [dispatch]
  );
}
