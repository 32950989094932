import React from 'react';

const defaultGetNextStep = step => step + 1;
const defaultState = { step: 1, stepHistory: [] };

export default function useStepHistory({ getNextStep = defaultGetNextStep }) {
  const [{ step, stepHistory }, setState] = React.useState(defaultState);

  const onReset = React.useCallback(() => setState(defaultState), [setState]);

  const onNext = React.useCallback(
    ({ nextStep = undefined, ...other } = {}) =>
      setState(prev => ({ ...prev, step: nextStep ?? getNextStep(prev.step), stepHistory: [...prev.stepHistory, prev.step], ...other })),
    [getNextStep, setState]
  );

  const handleBack = React.useCallback(
    () =>
      setState(prev => ({ ...prev, step: prev.stepHistory[prev.stepHistory.length - 1], stepHistory: prev.stepHistory.slice(0, prev.stepHistory.length - 1) })),
    [setState]
  );

  return { step, stepHistory, onNext, handleBack, onReset };
}
