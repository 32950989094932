import React from 'react';
import useUpdateLayout from '../store/actions/useUpdateLayout';
import { defaultScriptDetails } from '../store/reducers/layout';

export default function EmailComposerSelectScriptType({ onNext }) {
  const updateLayout = useUpdateLayout();
  const handleClick = React.useCallback(
    type => () => {
      updateLayout(prev => ({ ...prev, scriptDetails: { ...prev.scriptDetails, type } }));
      onNext({ nextStep: type === 'none' ? 7 : 6 });
    },
    [updateLayout, onNext]
  );

  React.useEffect(() => {
    updateLayout(prev => ({ ...prev, scriptDetails: defaultScriptDetails }));
  }, [updateLayout]);

  return (
    <div id='recipientsContainer' className={`selectRecipientsContainer`}>
      <div className='app__button-container--large'>
        <div className='app__button-div--large'>
          <button onClick={handleClick('select')} className='app__button app__button--large app__button--select-script'></button>
          <span>SELECT SCRIPT</span>
        </div>
        <div className='app__button-div--large'>
          <button onClick={handleClick('manual')} className='app__button app__button--large app__button--manual-script'></button>
          <span>ENTER SCRIPT MANUALLY</span>
        </div>
        <div className='app__button-div--large'>
          <button onClick={handleClick('none')} className='app__button app__button--large app__button--no-script'></button>
          <span>CONTINUE WITHOUT SCRIPT</span>
        </div>
      </div>
    </div>
  );
}
