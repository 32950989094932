import React, { Component } from 'react';
import { chop } from '../utils/helpers';

class CustomEmailSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      listDown: false
    };
    this.popDropDown = this.popDropDown.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  popDropDown = e => {
    if (this.state.listDown) {
      this.setState({ listDown: false });
    } else {
      this.setState({ listDown: true });
    }
  };

  collapse = e => {
    this.setState({ listDown: false });
    e.target.parentNode.parentNode.parentNode.blur();
  };

  handleClickOutside() {
    this.setState({
      listDown: false
    });
  }

  selectItem = (e, i, value) => {
    this.setState({
      listDown: false,
      selected: i
    });

    this.props.onSelect(value, this.props.contact);
    e.target.parentNode.parentNode.parentNode.blur();
  };

  render() {
    const items = this.props.options.map((item, index) => (
      <li className='unselectable' onClick={event => this.selectItem(event, index, item.value)} key={index}>
        {item.label}
      </li>
    ));

    if (this.props.options.length > 0) {
      return (
        <div
          className={this.props.highlight ? 'app__custom-select recipient-select active2' : 'app__custom-select recipient-select'}
          tabIndex='0'
          onBlur={event => {
            if (!this.props.isDisabled) {
              this.collapse(event);
            }
          }}
          onClick={event => {
            if (!this.props.isDisabled) {
              this.popDropDown(event);
            }
          }}
        >
          <div className='app__custom-select__main unselectable'>
            <p>{chop(this.props.options[this.state.selected].label, 52)}</p>
          </div>
          <div className='app__custom-select__list' style={{ display: this.state.listDown ? '' : 'none' }}>
            <div className='app__custom-select__list-items' style={{ top: this.state.listDown ? '0' : '-350px' }}>
              {items}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default CustomEmailSelect;
