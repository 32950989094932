import React from 'react';

export default function ConfirmDeleteModal({ isOpen = false, onConfirm, label = 'video' }) {
  const handleClick = React.useCallback(confirmed => () => onConfirm(confirmed), [onConfirm]);
  return (
    <div className={`${isOpen ? '' : 'hidden'}`}>
      <div className='app__modal overwrite__modal' id='confirm-delete-modal'>
        <div className='app__modal__container'>
          <div className='app__modal__great-job'>
            <span className='app__modal__heading'>Are you sure you want to delete this {label}?</span>
            <div className='app__modal__icons'>
              <div className='app__modal__icon-container'>
                <button className='app__send-email__button app__button' onClick={handleClick(false)} style={{ cursor: 'pointer' }}>
                  Cancel
                </button>
              </div>
              <div className='app__modal__icon-container'>
                <button
                  className='app__send-email__button app__button'
                  style={{ color: 'white', background: 'red', cursor: 'pointer' }}
                  onClick={handleClick(true)}                  
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
