import React from "react";
import { useDispatch } from "react-redux";
import { updateActiveBrandId } from ".";

export default function useUpdateActiveBrandId() {
  const dispatch = useDispatch();
  return React.useCallback(
    (id) => dispatch(updateActiveBrandId(id)),
    [dispatch]
  );
}
