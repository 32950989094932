import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { publicUrl } from '../../utils/helpers';
import Activity from '../pages/Activity';
import Profile from '../pages/Profile';
import Admin from '../pages/Admin';
import Videos from '../pages/Videos';
import Attachments from '../pages/Attachments';
import SendEmail from '../pages/SendEmail';

const Routes = () => (
    <Switch>
      <Route exact path={('/Videos')} component={Videos} />
      <Route exact path={publicUrl('/Attachments')} component={Attachments} />
      <Route exact path={publicUrl('/Activity')} component={Activity} />
      <Route exact path={publicUrl('/Profile')} component={Profile} />
      <Route exact path={publicUrl('/Admin')} component={Admin} />
      <Route path={'/SendEmail'} component={SendEmail} />
      <Route path={'/'} component={SendEmail} />
    </Switch>
);

export default Routes;