import React from 'react';

// Direct onChange handler
export default function useOnChange(setState) {
  return React.useCallback(id => value => setState(prev => ({ ...prev, [id]: value })), [setState]);
}

// Event driven on change handler
export const useHandleChange = setState => {
  const onChange = useOnChange(setState);
  return React.useCallback(id => event => onChange(id)(event?.target?.value), [onChange]);
};
