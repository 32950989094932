import React from 'react';
import useUser from '../store/selectors/useUser';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useApiPost from './useApiPost';
import { useDeleteVideo } from './useDeleteVideo';

const buildFormData = (user, bId) => {
  let formData = new FormData();
  formData.set('hID', 'getVideos');
  formData.set('uID', user.id);
  formData.set('bID', bId);
  return formData;
};

export const useVideos = ({ autoLoad = true } = {}) => {
  const user = useUser();
  const bId = useActiveBrand();
  const { data, loading, handlePost } = useApiPost();
  const { deleteVideo } = useDeleteVideo();

  const getVideos = React.useCallback(props => handlePost({ data: buildFormData(user, bId), ...props }), [bId, user, handlePost]);

  React.useEffect(() => {
    autoLoad && getVideos();
  }, [autoLoad, getVideos]);

  return { videos: data, loading, getVideos, deleteVideo };
};
