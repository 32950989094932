import React from 'react';
import Header from './Header';
import useActiveBrand from '../../store/selectors/useActiveBrand';
import SideBar from './SideBar';

export default function Layout({ children }) {
  const activeBrand = useActiveBrand();
  return (
    <div className={`repcast app skin1`}>
      {/* <div className='landscapeOverlay'>
        <p>Small mobile devices are not supported.</p>
      </div> */}
      <SideBar />
      <div className='app__main-container'>
        <Header />
        <div className='app__container'>{children}</div>
      </div>
    </div>
  );
}
