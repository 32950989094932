import React from 'react';
import useApiPost from './useApiPost';
import config from 'react-global-configuration';

const buildFormData = ({ email }) => {
  let formData = new FormData();
  formData.set('hID', 'sendResetLink');
  formData.set('email', email);
  return formData;
};

export default function useSendResetEmail() {
  const { handlePost, loading } = useApiPost();

  const sendResetEmail = React.useCallback(
    ({ email, onSuccess = undefined, onError = undefined }) =>
      handlePost({
        url: config.get('apiUrl') + 'login.php',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: buildFormData({ email }),
        onSuccess: response => {
          if (response.data.status === 'false' || response.data.status === 'fail') {
            onError && onError(response);
            console.log('Error sending reset link!');
          } else {
            onSuccess && onSuccess(response);
          }
        },
        onError: response => {
          onError && onError(response);
          console.error('Error sending reset link!', response);
        }
      }),
    [handlePost]
  );

  return { sendResetEmail, sending: loading };
}
