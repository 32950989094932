import { v4 as uuidv4 } from 'uuid';
import packageJson from '../../package.json';

export const publicUrl = path => process.env.PUBLIC_URL + path;

export function hostAddress(append) {
  return (
    window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + (append !== undefined ? append : '')
  );
}

export function printHeader() {
  console.log(
    `%c
█████╗ █████╗█████╗  ████╗ ████╗ █████╗██████╗TM
██╔═██╗██╔══╝██╔═██╗██╔══╝██╔═██╗██═══╝╚═██╔═╝
█████╔╝████╗ █████╔╝██║   ██████║█████╗  ██║  
██╔═██╗██╔═╝ ██╔══╝ ██║   ██╔═██║╚══██║  ██║  
██║ ██║█████╗██║    ╚████╗██║ ██║█████║  ██║  
╚═╝ ╚═╝╚════╝╚═╝     ╚═══╝╚═╝ ╚═╝╚════╝  ╚═╝  
█████╗██████╗██╗ ██╗█████╗ ██╗ ████╗            
██╔══╝╚═██╔═╝██║ ██║██╔═██╗██║██╔═██╗           
█████╗  ██║  ██║ ██║██║ ██║██║██║ ██║           
╚══██║  ██║  ██║ ██║██║ ██║██║██║ ██║           
█████║  ██║  ╚████╔╝█████╔╝██║╚████╔╝           
╚════╝  ╚═╝   ╚═══╝ ╚════╝ ╚═╝ ╚═══╝

Host: ${hostAddress()}

Environment: ${process.env.NODE_ENV}
Version: ${packageJson.version}                         
`,
    'font-family:monospace;color: #2699fb;font-size:12px;'
  );
}

export function uuid() {
  return uuidv4();
}

export function isActiveFeature(name) {
  const features = JSON.parse(localStorage.getItem('flags'));
  const feature = features.find(feature => feature.name === name.toLowerCase());
  
  if(feature === undefined) {
      return true;
  } else if (feature && feature.active === '1') {
      return true;
  }
  return false;
}

export function validateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );
}

export function validateFilename(filename) {
  return /^[0-9a-zA-Z ... ]+$/.test(String(filename));
}

export function ensureExtension(name, ext) {
  return name.toLowerCase().endsWith(ext) ? name : name + ext; // Ensure name contains single extension since user may or may not enter an extension
}

// Blob duration in seconds.
export default async function getBlobDuration(blob) {
  const tempVideoEl = document.createElement('video');

  const durationP = new Promise((resolve, reject) => {
    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration);
          tempVideoEl.currentTime = 0;
        };
      }
      // Normal behavior
      else resolve(tempVideoEl.duration);
    });
    tempVideoEl.onerror = event => reject(event.target.error);
  });

  tempVideoEl.src = typeof blob === 'string' || blob instanceof String ? blob : window.URL.createObjectURL(blob);

  return durationP;
}

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function yyyymmdd(date, daysToAdd = 0) {
  date = date ? date : new Date(); //If we don't pass a date in, then create it

  if (daysToAdd > 0) {
    date = addDays(date, daysToAdd);
  }

  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
}

export const sortAscending = sortProperty => (a, b) => { 
  const aProp = (typeof a[sortProperty] === "string" ? a[sortProperty].toLowerCase() : a[sortProperty]);
  const bProp = (typeof b[sortProperty] === "string" ? b[sortProperty].toLowerCase() : b[sortProperty]);
  return aProp > bProp ? 1 : -1;
}
export const sortDescending = sortProperty => (a, b) => {
  const aProp = (typeof a[sortProperty] === "string" ? a[sortProperty].toLowerCase() : a[sortProperty]);
  const bProp = (typeof b[sortProperty] === "string" ? b[sortProperty].toLowerCase() : b[sortProperty]);
  return aProp > bProp ? -1 : 1;
} 

// If a function, then return the result of the function call with props passed in.  Else return the value
export const evalFunc = (value, props = undefined) => (typeof value === 'function' ? value(props) : value);

export const chop = (st, len) => (len <= st.length ? st.substr(0, len) + '...' : st);

export const clearUrlParams = () => {
  if (!isEmpty(window.location.search)) {
    window.location.search = '';
  }
};
