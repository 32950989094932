import React, { Component } from "react";

class ImageModal extends Component {

  render() {
    return (
      <div className="app__modal image__modal">
        <div className="app__modal__container">
          <button className="close" onClick={this.props.handleClose}>
            x
          </button>

          <div>
            show image
          </div>
        </div>
      </div>
    );
  }
}

export default ImageModal;
