import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocumentModal extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div className="app__modal document__modal">
        <div className="app__modal__container">
          <button className="close" onClick={this.props.handleClose}>
            x
          </button>

          <div>
            <Document
              file={this.props.path}
              onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
              onLoadError={console.error}
              height="300"
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <button
              className={pageNumber === 1 ? "hidden" : ""}
              onClick={() =>
                this.setState((prevState) => ({
                  pageNumber: prevState.pageNumber - 1,
                }))
              }
            >
              Previous
            </button>
            <button
              className={pageNumber === numPages ? "hidden" : ""}
              onClick={() =>
                this.setState((prevState) => ({
                  pageNumber: prevState.pageNumber + 1,
                }))
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentModal;
