import React from 'react';
import useUpdateLayout from '../store/actions/useUpdateLayout';

export default function ScriptManualEntry() {
  const updateLayout = useUpdateLayout();
  const handleChange = React.useCallback(
    name => event => {
      const value = event?.target?.value;
      updateLayout(prev => ({ ...prev, scriptDetails: { ...prev.scriptDetails, [name]: value } }));
    },
    [updateLayout]
  );
  return (
    <div className='app__container script-grid'>
      <p>
        <b>Enter script manually</b>
      </p>
      <div id='emailGridContainer' className='app__script-grid__container'>
        <textarea name='manualScript' cols='60' rows='8' placeholder='Enter intro.' onChange={handleChange('intro')}></textarea>
        <br />
        <textarea name='manualScript' cols='60' rows='8' placeholder='Enter main content.' onChange={handleChange('contentA')}></textarea>
        <br />
        <textarea name='manualScript' cols='60' rows='8' placeholder='Enter secondary content.' onChange={handleChange('contentB')}></textarea>
        <br />
        <textarea name='manualScript' cols='60' rows='8' placeholder='Enter closing and click next when done.' onChange={handleChange('closing')}></textarea>
        <br />
      </div>
    </div>
  );
}
