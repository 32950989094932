import { createStore, compose, combineReducers } from 'redux';
import { reducers } from './store';

export default function configureStore(initialState) {
  // Build middleware. These are functions that can process the actions before they reach the store.
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  // If devTools is installed, connect to it
  const devToolsExtension = process.env.NODE_ENV === 'development' && windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;

  const allReducers = buildRootReducer(reducers);
  const createStoreWithMiddleware = compose(devToolsExtension ? devToolsExtension() : f => f)(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  const store = createStoreWithMiddleware(allReducers, initialState);

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('./store', () => {
      const nextRootReducer = require('./store');
      store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
    });
  }

  return store;
}

function buildRootReducer(allReducers) {
  return combineReducers(Object.assign({}, allReducers, {}));
}