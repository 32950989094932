import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from './useApiPost';

const buildFormData = (user) => {
  let formData = new FormData();
  formData.set('hID', 'getAllUsers');
  formData.set('uID', user.id);
  return formData;
};

export const useReps = ({ autoLoad = true } = {}) => {
  const { data, loadingUsers, handlePost } = useApiPost();
  const user = useUser();

  const getAllReps = React.useCallback(props => handlePost({ data: buildFormData(user), ...props }), [handlePost, user]);

  React.useEffect(() => {
    autoLoad && getAllReps();
  }, [autoLoad, getAllReps]);

  return { users: data, loadingUsers, getAllReps };
};
