import React, { useEffect, useRef } from 'react';
import { useRecordingDuration } from './ReactMediaRecorder';

export default function VideoPreview({ stream, status }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const duration = useRecordingDuration(status);
  const mins = String(Math.floor(duration / 60)).padStart(2, '0');
  const secs = String(duration - mins * 60).padStart(2, '0');
  return !stream ? null : (
    <>
      <div className='app__video__preview-timer'>
        {mins}:{secs}
      </div>
      ;
      <video id='video1' ref={videoRef} autoPlay muted={true} />
    </>
  );
}
