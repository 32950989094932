import React from 'react';
import useUser from '../store/selectors/useUser';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useApiPost from './useApiPost';

const buildFormData = (id, type, user, bId) => {
  let formData = new FormData();
  formData.set('hID', 'deleteAttachment');
  formData.set('uID', user.id);
  formData.set('bID', bId);
  formData.set('aID', id);
  formData.set('type', type);
  return formData;
};

export const useDeleteAttachment = () => {
  const { handlePost } = useApiPost();
  const user = useUser();
  const bId = useActiveBrand();
  const deleteAttachment = React.useCallback(
    ({ id, type, ...other }) => {
      handlePost({ data: buildFormData(id, type, user, bId), ...other });
    },
    [bId, user, handlePost]
  );

  return { deleteAttachment };
};
