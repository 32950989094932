import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../actions';

function useOpenEmailModal() {
  const dispatch = useDispatch();
  return React.useCallback(
    (isEmailModalOpen = true, emailModalType = 'send', successEmails = [], failedEmails = []) =>
      dispatch(updateLayout({ isEmailModalOpen, emailModalType, successEmails, failedEmails })),
    [dispatch]
  );
}

function useHandleOpenEmailModal() {
  const openEmailModal = useOpenEmailModal();
  return React.useCallback(
    (isEmailModalOpen, type, successEmails, failedEmails) => e => openEmailModal(isEmailModalOpen, type, successEmails, failedEmails),
    [openEmailModal]
  );
}

export default function useEmailModal() {
  const open = useSelector(state => state.layout.isEmailModalOpen);
  const type = useSelector(state => state.layout.emailModalType);
  const successEmails = useSelector(state => state.layout.successEmails);
  const failedEmails = useSelector(state => state.layout.failedEmails);
  const openEmailModal = useOpenEmailModal();
  const handleOpenEmailModal = useHandleOpenEmailModal();
  return { open, type, successEmails, failedEmails, openEmailModal, handleOpenEmailModal };
}
