import React from "react";
import { useSelector } from "react-redux";
import useScroll from "../utils/useScroll";

export default function AutoScrollScript({
  scrollSpeed = 0,
  fontSize = 20,
  scriptPosition = 0,
  retakeCount = 0, // flag to reset the scroll position to the top (for re-take operation)
}) {
  const intro = useSelector((state) => state.layout.scriptDetails.intro);
  const contentA = useSelector((state) => state.layout.scriptDetails.contentA);
  const contentB = useSelector((state) => state.layout.scriptDetails.contentB);
  const closing = useSelector((state) => state.layout.scriptDetails.closing);

  const ref = React.useRef();
  const speedRef = React.useRef(0); // Stores scroll speed as a pointer reference to allow updates

  const { scrollTo, isScrolling } = useScroll({
    ref,
    speedRef,
    direction: "y",
  });

  React.useEffect(() => {
    // Reset the scroll position to the top every time a new re-take is performed.
    retakeCount > 0 && ref.current && ref.current.scrollTo(0, 0);
  }, [retakeCount]);

  React.useEffect(() => {
    speedRef.current = scrollSpeed;
    !isScrolling && scrollTo("#scroll-bottom");
  }, [isScrolling, scrollTo, speedRef, scrollSpeed]);

  return (
    <div
      id="scriptContainer"
      ref={ref}
      className={
        scriptPosition ? "script__wrapper_bottom" : "script__wrapper_top"
      }
      style={{ fontSize }}
    >
      <div className="script__shadow-overlay"></div>
      {intro}
      <br />
      <br />
      {contentA}
      <br />
      <br />
      {contentB}
      <br />
      <br />
      {closing}
      <div id="scroll-bottom"></div>
    </div>
  );
}
