import { useSelector } from 'react-redux';
import useUpdateSelectedContacts from '../actions/useUpdateSelectedContacts';

export default function useSelectedContacts() {
  return {
    updateSelectedContacts: useUpdateSelectedContacts(),
    selectedContacts: useSelector(state =>
      state.layout.selectedContacts.map(sc => ({
        ...sc,
        toEmail: (state.layout.selectedEmails[sc.id] && sc[state.layout.selectedEmails[sc.id]]) ?? sc.toEmail
      }))
    )
  };
}
