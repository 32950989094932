import React from 'react';
import SignIn from '../SignIn';
import useIsLoggedIn from '../../store/selectors/useIsLoggedIn';

export default function AuthGate({ children }) {
  var isLoggedIn = useIsLoggedIn();

  React.useEffect(() => {
    if (!isLoggedIn) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('expire_at');
      localStorage.removeItem('tmpUser');
    }
  }, [isLoggedIn]);

  return isLoggedIn ? children : <SignIn />;
}
