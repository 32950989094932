import React from 'react';
import { useRef, useState } from 'react';

export function useMediaRecorder({ audio = true, video = false, onStop = () => null, blobPropertyBag }) {
  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);
  const mediaStream = useRef(null);
  const [status, setStatus] = useState('idle');
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [error, setError] = useState('NONE');

  const closeExisting = React.useCallback(() => {
    mediaRecorder?.current && mediaRecorder.current.state !== 'inactive' && mediaRecorder.current.stop();
    mediaStream?.current && mediaStream.current.getTracks && mediaStream.current.getTracks().forEach(track => track.stop());
  }, []);

  React.useEffect(() => {
    if (!window.MediaRecorder) {
      console.error('Unsupported Browser');
    } else {
      setStatus('acquiring_media');
      if (mediaStream.current) {
        closeExisting(); // If stream already exists then reset
      }

      

      /* Note: Constraints can be passed through instead of just a bool, which specify minimum requirements (or max/ideal)
      for the users camera in order for this module to work. If not met, an error will be thrown:
      video: {
            height: { min: 720, max: 1280 },
            width: { min: 1080, max: 1920 },
            frameRate: { min: 15, ideal: 24, max: 30 }
          }
      */

        // Article on constraints
      // https://webrtchacks.com/getusermedia-resolutions-3/
      // https://webrtc.github.io/samples/src/content/getusermedia/resolution/

     //854 x 480 16:9
     // 500 x 281 was half size
     // 750 x 428
     // jusat height 281 - 3.3 for 10 seconds black screen
     // 640×480, and 320×240 ? 
     // const useVideo = (typeof video === 'boolean') && video === true ? { height: { min: 240, ideal: 480, max: 480 } } : false;
     // const useVideo = (typeof video === 'boolean') && video === true ? { height: { ideal: 400 } } : false;
     const useVideo = (typeof video === 'boolean') && video === true ? { width: { exact: 500 } } : false;
      window.navigator.mediaDevices
        .getUserMedia({
          audio: typeof audio === 'boolean' ? !!audio : audio,
          video: useVideo// typeof video === 'boolean' ? !!video : video
        })
        .then(stream => {
          if (mediaStream) mediaStream.current = stream; // Set active stream

          const track = stream.getVideoTracks()[0];
          let stream_settings = track.getSettings();

          const constraints = {width: {exact: 500}};
          // {width: {max: 640}} 3315481 
          track.applyConstraints(constraints)
          .then(() => {
            let video = document.querySelector('video');
            // console.log('Actual video dimensions: ' + video.videoWidth +'px' + video.videoHeight + 'px.');

            // actual width & height of the camera video
            let stream_width = stream_settings.width;
            let stream_height = stream_settings.height;
            
          })
          .catch(err => {
            console.log('error applyConstraints', err.name);
          });
          
          setStatus('idle');
        })
        .catch(error => {
          setError(error.name);
          setStatus('idle');
        });
    }
    return () => {
      closeExisting();
    };
  }, [audio, video, closeExisting]);

  const onRecordingActive = React.useCallback(({ data }) => {
    mediaChunks.current.push(data);
  }, []);

  const onRecordingStop = React.useCallback(() => {
    const [chunk] = mediaChunks.current;
    const blobProperty = Object.assign({ type: chunk.type }, blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' }));
    //const blobProperty = Object.assign({ type: chunk.type }, blobPropertyBag || (video ? { type: 'video/webm;codecs=vp8' } : { type: 'audio/wav' }));
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus('stopped');
    setMediaBlobUrl(url);
    onStop(url, blob);
    // eslint-disable-next-line
  }, [video, onStop, setStatus, setMediaBlobUrl, JSON.stringify(blobPropertyBag)]);

  const startRecording = React.useCallback(() => {
    setError('NONE');
    if (!mediaStream.current) {
      console.error('Error starting recording.  Media stream is not open.');
    } else {
      const isStreamEnded = mediaStream.current.getTracks().some(track => track.readyState === 'ended');
      const options = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
      };

      if (isStreamEnded) {
        alert('stream is ended!');
        console.error('Error starting recording.  Media stream has ended.');
      }

      if (!mediaRecorder.current) {
        mediaRecorder.current = new MediaRecorder(mediaStream.current, options);
        mediaRecorder.current.ondataavailable = onRecordingActive;
        mediaRecorder.current.onstop = onRecordingStop;
        mediaRecorder.current.onerror = () => {
          console.error('mediaRecorder callback error (onerror)');
          setError('NO_RECORDER');
          setStatus('idle');
        };
      }
      setStatus('recording');
      mediaRecorder.current.start();
    }

    return mediaStream.current;

  }, [onRecordingActive, onRecordingStop, setStatus]);

  const stopRecording = React.useCallback(() => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping', mediaRecorder, mediaStream);
        mediaRecorder.current.stop();
        mediaChunks.current = [];
      }
    }
  }, []);

  const vt = mediaStream?.current?.getVideoTracks();
  const { height, width } = vt?.length > 0 ? vt[0].getSettings() : {};

  return {
    status,
    previewStream: mediaStream.current,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error: [error],
    clearBlobUrl: () => setMediaBlobUrl(null),
    height,
    width
  };
}
