import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, name, screenshot }) => {
  let formData = new FormData();
  formData.set('hID', 'updateUserPhoto');
  formData.set('uID', uId);
  formData.set('name', name);
  formData.set('img', screenshot);
  return formData;
};

export default function useUpdateUserPhoto() {
  const { loading, handlePost } = useApiPost();
  const { id: uId, name } = useUser();

  const updateUserPhoto = React.useCallback(
    ({ screenshot, ...other }) =>
      handlePost({
        data: buildFormData({ uId, name, screenshot }),
        ...other
      }),
    [uId, name, handlePost]
  );

  return { updateUserPhoto, loading };
}
