import React from 'react';
import { isActiveFeature } from '../utils/helpers.js';

export default function EmailComposerSelectVideoType({ onSelect }) {
  return (
    <div className='app__button-container--large'>
      {isActiveFeature('skip_video') && 
        <div className='app__button-div--large'>
          <button onClick={onSelect('skip')} className='app__button app__button--large app__button--no-video'></button>
          <span>Skip Video</span>
        </div>
      }
      <div className='app__button-div--large'>
        <button onClick={onSelect('create')} className='app__button app__button--large app__button--create-video'></button>
        <span>Create Video</span>
      </div>
      <div className='app__button-div--large'>
        <button onClick={onSelect('select')} className='app__button app__button--large app__button--library'></button>
        <span>
          Select
          <br />
          From Library
        </span>
      </div>
    </div>
  );
}
