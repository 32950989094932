import React from 'react';

export default function ConfirmUploadModal({ isOpen = false, onConfirm }) {
  const handleClick = React.useCallback(confirmed => () => onConfirm(confirmed), [onConfirm]);
  return (
    <div className={`${isOpen ? '' : 'hidden'}`}>
      <div className='app__modal overwrite__modal' id='confirm-upload-modal'>
        <div className='app__modal__container'>
          <div className='app__modal__great-job'>
            <span className='app__modal__upload__heading'>You already have an attachment with this title. Would you like to edit this title, or save anyway?</span>
            <div className='app__modal__icons'>
              <div className='app__modal__icon-container'>
                <button className='app__send-email__button app__button' onClick={handleClick(false)} style={{ cursor: 'pointer' }}>
                  Cancel &amp; Edit
                </button>
              </div>
              <div className='app__modal__icon-container'>
                <button
                  className='app__send-email__button app__button'
                  style={{ color: 'white', background: 'red', cursor: 'pointer' }}
                  onClick={handleClick(true)}                  
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
