import React from "react";
import { useDispatch } from "react-redux";
import { updateSelectedVideo } from ".";

export default function useUpdateSelectedVideo() {
  const dispatch = useDispatch();
  return React.useCallback(
    (vid) => dispatch(updateSelectedVideo(vid)),
    [dispatch]
  );
}
