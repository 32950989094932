import { useRef, useState, useEffect } from 'react';

const getAttrMap = direction => {
  return {
    leftTop: 'x' === direction ? 'left' : 'top',
    offsetLeftTop: 'x' === direction ? 'offsetLeft' : 'offsetTop',
    offsetWidthHeight: 'x' === direction ? 'offsetWidth' : 'offsetHeight',
    scrollLeftTop: 'x' === direction ? 'scrollLeft' : 'scrollTop',
    scrollWidthHeight: 'x' === direction ? 'scrollWidth' : 'scrollHeight',
    clientWidthHeight: 'x' === direction ? 'clientWidth' : 'clientHeight'
  };
};

function debounce(cb, delay = 100) {
  let timer;
  return function (...args) {
    const _this = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      cb.apply(_this, args);
    }, delay);
  };
}

const isWindowScrollParent = elm => {
  return !elm.parentElement || !elm.parentElement.parentElement;
};

// get the relative distance from destination
const getRelativeDistance = (target, parent, attrMap) => {
  if (typeof target === 'number') return target;
  if (typeof target === 'string') {
    const elm = document.querySelector(target);
    if (!elm) {
      console.warn('Please pass correct selector string for scrollTo()!');
      return 0;
    }
    let dis = 0;

    // if parent is document.documentElement or document.body
    if (isWindowScrollParent(parent)) {
      dis = elm.getBoundingClientRect()[attrMap.leftTop];
    } else {
      dis = elm.getBoundingClientRect()[attrMap.leftTop] - parent.getBoundingClientRect()[attrMap.leftTop];
    }

    return dis;
  }
  return 0;
};

export default function useScroll({ ref, speedRef, direction = 'y', threshold = 1 }) {
  const attrMap = getAttrMap(direction);

  const [reachedTop, setReachedTop] = useState(true);
  const [reachedBottom, setReachedBottom] = useState(true);
  const [size, setSize] = useState(0);

  const isScrollingStore = useRef(false);
  const scrollLeftTopRef = useRef(0);

  const isTopEdge = () => {
    const elm = ref.current;
    if (!elm) return false;
    return elm[attrMap.scrollLeftTop] === 0;
  };

  const isBottomEdge = () => {
    const elm = ref.current;
    if (!elm) return false;
    return Math.abs(elm[attrMap.scrollLeftTop] + elm[attrMap.clientWidthHeight] - elm[attrMap.scrollWidthHeight]) < threshold;
  };

  const refreshSize = debounce(() => {
    if (ref.current) {
      const size = ref.current[attrMap.clientWidthHeight];
      setSize(size);
    }
  });

  const refreshState = debounce(_evt => {
    if (ref.current) {
      isTopEdge() ? setReachedTop(true) : setReachedTop(false);
      isBottomEdge() ? setReachedBottom(true) : setReachedBottom(false);
    }
  });

  const scrollTo = (target = undefined) => {
    if (isScrollingStore.current) {
      console.warn('Scrolling is already in progress! \n');
      return;
    }
    if (!ref || !ref.current) {
      console.warn('Please pass `ref` property for your scroll container! \n');
      return;
    }
    const elm = ref.current;
    if (!elm) return;
    if (!target && typeof target !== 'number') {
      console.warn('Please pass a valid property for `scrollTo()`! \n');
    }

    const initScrollLeftTop = elm[attrMap.scrollLeftTop];

    let distance = getRelativeDistance(target, elm, attrMap);

    const cb = () => {
      refreshState();
      if (distance === 0) {
        isScrollingStore.current = false;
        return;
      }

      if ((isBottomEdge() && distance > 9) || (distance < 0 && isTopEdge())) {
        isScrollingStore.current = false;
        return;
      }

      const gone = () => Math.abs(elm[attrMap.scrollLeftTop] - initScrollLeftTop);

      if (Math.abs(distance) - gone() < speedRef.current) {
        speedRef.current = Math.abs(distance) - gone();
      }

      // distance to run every frame，always 1/60s
      const delta = distance > 0 ? speedRef.current : -1;
      if (delta > 0) {
        scrollLeftTopRef.current += delta; // Must store in a separate data store as the browser can't handle decimals in the elm[scrollLeftTop]
        elm[attrMap.scrollLeftTop] = scrollLeftTopRef.current;
      }

      // reach destination, threshold defaults to 1
      if (Math.abs(gone() - Math.abs(distance)) < threshold) {
        isScrollingStore.current = false;
        return;
      }

      if (speedRef.current > 0) {
        requestAnimationFrame(cb);
      } else {
        isScrollingStore.current = false;
        return;
      }
    };

    if (speedRef.current > 0) {
      isScrollingStore.current = true;
      scrollLeftTopRef.current = elm[attrMap.scrollLeftTop];
      requestAnimationFrame(cb);
    } else {
      isScrollingStore.current = false;
    }
  };

  // detect dom changes
  useEffect(() => {
    if (!ref.current) return;

    refreshState();
    refreshSize();
    const observer = new MutationObserver((mutationsList, _observer) => {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.target instanceof Element) {
          refreshSize();
        }
      }
    });
    observer.observe(ref.current, {
      attributes: true
    });
    window.addEventListener('resize', refreshSize);
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', refreshSize);
    };
  }, [ref, refreshState, refreshSize]);

  // detect scrollbar changes
  useEffect(() => {
    if (!ref.current) return;
    const elm = ref.current;
    const observer = new MutationObserver((mutationsList, _observer) => {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.target instanceof Element) {
          refreshState();
        }
      }
    });
    observer.observe(elm, {
      childList: true,
      subtree: true
    });
    elm.addEventListener('scroll', refreshState);
    return () => {
      observer.disconnect();
      elm && elm.removeEventListener('scroll', refreshState);
    };
  }, [ref, refreshState]);

  return {
    reachedTop,
    reachedBottom,
    containerSize: size,
    isScrolling: isScrollingStore.current,
    scrollTo
  };
}
