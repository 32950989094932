import React from 'react';
import useApiPost from '../api/useApiPost';
import config from 'react-global-configuration';

const buildFormData = ({ email, password, confirmPassword, resetToken }) => {
  let formData = new FormData();
  formData.set('hID', 'resetPassword');
  formData.set('email', email);
  formData.set('pw', password);
  formData.set('pwCopy', confirmPassword);
  formData.set('token', resetToken);
  return formData;
};

export default function useResetPassword() {
  const { handlePost, loading } = useApiPost();

  const resetPassword = React.useCallback(
    ({ email, password, confirmPassword, resetToken, onSuccess = undefined, onError = undefined }) =>
      handlePost({
        url: config.get('apiUrl') + 'login.php',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: buildFormData({ email, password, confirmPassword, resetToken }),
        onSuccess: response => {
          if (response.data.status === 'false' || response.data.status === 'fail') {
            onError && onError(response);
            console.log('Error sending reset link!');
          } else {
            onSuccess && onSuccess(response);
          }
        },
        onError: response => {          
          onError && onError(response);
          console.error('Error sending reset link!', response);
        }
      }),
    [handlePost]
  );

  return { resetPassword, resetting: loading };
}
