import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useUser from '../store/selectors/useUser';
import ConfirmUploadModal from './ConfirmUploadModal';
import useSaveAttachment from '../api/useSaveAttachment';

export default function EditAttachmentModal({ isOpen, onClose, attachment, brands }) {
  const [{ file, filename, title, confirmTitleSave, saveSameTitle, doSubmit, didSave, isLoading, uploadedFiles, message, uploadPercentage, bId}, setState] = React.useState({
    file: '',
    filename: '',
    title: '',
    confirmTitleSave: false,
    saveSameTitle: false,
    doSubmit: false,
    didSave: false,
    isLoading: false,
    uploadedFiles: [],
    message: '',
    uploadPercentage: 0,
    bId: 1
  });
  const fileInput = React.useRef();
  const submitBtn = React.useRef();
  const cancelBtn = React.useRef();
  const { id: uId } = useUser();

  const { saveAttachment } = useSaveAttachment();

  React.useEffect(() => {
    if (attachment) {
      setState(prev => ({ ...prev, title: attachment.title, bId: attachment.bId}));
    }
  }, [attachment]);

  const onChangeFile = e => {
    setState(prev => ({ ...prev, file: e.target.files[0], filename: e.target.files[0].name }));
  };

  const onChangeTitle = e => {
    setState(prev => ({ ...prev, title: e.target.value }));
  };

  React.useEffect(() => {
    if(doSubmit) {
      submitBtn.current.click();
    }
  }, [saveSameTitle]);

  React.useEffect(() => {
    if(didSave) {
      cancelBtn.current.click();
    }
  }, [uploadedFiles]);

  const handleConfirmTitleSave = React.useCallback(
    confirmed => {
      if(confirmed) {
        setState(prev => ({ ...prev, confirmTitleSave: false, saveSameTitle: true, doSubmit: true }));
        
      } else {
        setState(prev => ({ ...prev, confirmTitleSave: false, saveSameTitle: false}));
      }
    },
    [setState, submitBtn]
  );

  const isFormValid = React.useCallback(() => {
    let allowedExtensions = /(\.mp4)$/i;
    let invalidType = 'Invalid file type. Please upload a .mp4';
    if (attachment.path.indexOf('.pdf') > -1) {
      allowedExtensions = /(\.pdf)$/i;
      invalidType = 'Invalid file type. Please upload a .pdf.';
    }

    if (!filename && ((!title.trim() || title.trim() === attachment.title) && (attachment.bID === bId))) {
      setState(prev => ({ ...prev, message: 'Please upload a new file or enter a new title or Brand.', didSave: false }));
      return false;
    } else if (filename && !allowedExtensions.exec(filename)) {
      setState(prev => ({ ...prev, message: invalidType, didSave: false }));
      return false;
    } else if (title.trim() === '') {
      setState(prev => ({ ...prev, message: 'Title cannot be blank.', didSave: false }));
      return false;
    } else {
      setState(prev => ({ ...prev, message: '' }));
      return true;
    }
  },
    [filename, title]
  );

  const handleSave = React.useCallback(
    (e) => {
      e.preventDefault();
     
      if(isFormValid()) {
        let newTitle = title.trim() === attachment.title ? '' : title.trim();
        let fileType = attachment.path.indexOf('.pdf') > -1 ? 'documents' : attachment.path.indexOf('.mp4') > -1 ? 'programs' : 'images';
        let fileId = attachment.id;

        saveAttachment({
          uId, bId, fileId, newTitle, fileType, filename, file, saveSameTitle,
          onProgress: progressEvent => {
            const perc = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            setState(prev => ({ ...prev, uploadPercentage: perc }));
          },
          onSuccess: res => {
            if (res.data.status === 'warning' && res.data.code === 'Title exists') {

              setState(prev => ({ ...prev, confirmTitleSave: true, doSubmit: false, isLoading: false, saveSameTitle: false, didSave: false }));

            } else if (res.data.status === 'success') {
              const newPath = res.data.path ? res.data.path : attachment.path;
              const newFiles = [
                ...uploadedFiles,
                {
                  name: title,
                  path: newPath
                }
              ];

              fileInput.current.value = '';
              setState(prev => ({ ...prev, title: '', file: '', filename: '', doSubmit: false, isLoading: false, message: '', uploadedFiles: newFiles, saveSameTitle: false, uploadPercentage: 0, didSave: true }));
            } else {
              setState(prev => ({ ...prev, doSubmit: false, isLoading: false, message: 'There was a problem uploading this item.', uploadPercentage: 0, didSave: false }));
            }
          },
          onError: () => {
            setState(prev => ({ ...prev, doSubmit: false, isLoading: false, message: 'There was a problem uploading this item.', uploadPercentage: 0, didSave: false }));
          }
        })
      }
    },
    [uId, bId, title, filename, file, saveSameTitle, onClose]
  );

  return (
    <>
      <div className={`${isOpen ? '' : 'hidden'}`}>
        <div className='app__modal edit__attachment__modal'>
          <div className='app__modal__container'>
            <div className='app__modal__great-job'>
              <span className='app__modal__heading'>Edit Attachment</span>
              <div className="upload__message">{message ? <>{message}</> : null}</div>
              <div className="app__upload__form">
                <input
                  type='file'
                  className='app__attachments__filename'
                  id='customFile'
                  ref={fileInput}
                  onChange={onChangeFile}
                  disabled={isLoading}
                />

                <input
                  type='text'
                  className='app__attachments__file_title'
                  id='customFileTitle'
                  value={title}
                  placeholder='Document/Video/Image Title'
                  onChange={onChangeTitle}
                  disabled={isLoading}
                />
              </div>

              <select className="app__attachments__brand"  value={bId} onChange={e => {
                  setState(prev => ({ ...prev, bId: e.target.value}));
                }}>
                {brands.map((brand, index) => (
                  <option className='unselectable' value={brand.bID} key={index}>
                    {brand.brandname}
                  </option>
                ))}
              </select>

              <div className="app__attachments__progress">
                <CircularProgressbar value={uploadPercentage} text={`${uploadPercentage}%`} styles={buildStyles({
                  textColor: "#199dde",
                  pathColor: "#199dde",
                  trailColor: "white"
                })} />
              </div>

              <div className='app__modal__icons'>
                <div className='app__modal__icon-container'>
                  <button className='app__button button__cancel' onClick={onClose(didSave)} style={{ cursor: 'pointer' }} disabled={isLoading} ref={cancelBtn}>
                    Cancel
                  </button>
                </div>
                <div className='app__modal__icon-container'>
                  <button
                    className='app__button'
                    style={{ cursor: 'pointer' }}
                    onClick={handleSave}
                    disabled={isLoading}
                    ref={submitBtn}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmUploadModal isOpen={confirmTitleSave} onConfirm={handleConfirmTitleSave} />
      
    </>
  );
}
