import React from 'react';

export default function TimeZone({ value, onChange }) {
  return (
    <select className="app__small-input" id='timeZone' value={value} onChange={onChange}>
      <option value='US/Hawaii'>(GMT-10:00) Hawaii</option>
      <option value='US/Alaska'>(GMT-09:00) Alaska</option>
      <option value='US/Pacific'>(GMT-08:00) Pacific Time (US &amp; Canada)</option>
      <option value='US/Arizona'>(GMT-07:00) Arizona</option>
      <option value='US/Mountain'>(GMT-07:00) Mountain Time (US &amp; Canada)</option>
      <option value='US/Central'>(GMT-06:00) Central Time (US &amp; Canada)</option>
      <option value='US/Eastern'>(GMT-05:00) Eastern Time (US &amp; Canada)</option>
    </select>
  );
}
