import React from 'react';
import useTimer from '../utils/useTimer';
import { useMediaRecorder } from './useMediaRecorder';

export function useRecordingDuration(status) {
  const { start, stop, seconds, isRunning } = useTimer();
  React.useEffect(() => {
    if (status === 'recording' && isRunning === false) {
      start();
    } else if (status !== 'recording') {
      stop();
    }
  }, [status, isRunning, start, stop]);
  return seconds;
}

export const ReactMediaRecorder = props => props.render(useMediaRecorder(props));
