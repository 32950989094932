import React from 'react';
import { chop } from '../utils/helpers';

export default function CustomSelect({ value = '', options, onSelect, type, isDisabled }) {
  const [{ listDown }, setState] = React.useState({ listDown: false });

  const openDropDown = React.useCallback(e => setState(prev => ({ ...prev, listDown: !listDown })), [listDown]);
  const closeDropDown = React.useCallback(e => setState(prev => ({ ...prev, listDown: false })), []);

  const selectItem = React.useCallback(
    option => e => {
      e.stopPropagation(); // Stop event propogation so click doesn't fire on parent
      onSelect(type, option);
      e.target.parentNode.parentNode.parentNode.blur(); // Blur parent to trigger closing of dropdown
    },
    [type, onSelect]
  );

  return options.length <= 0 ? (
    <></>
  ) : (
    <div
      className={`app__custom-select ${type === 'onSubject' ? 'subject' : ''}`}
      tabIndex='0'
      onBlur={!isDisabled && closeDropDown}
      onClick={!isDisabled && openDropDown}
    >
      <div className='app__custom-select__main unselectable'>
        <p>{chop(value, 52)}</p>
      </div>
      <div className='app__custom-select__list' style={{ display: listDown ? '' : 'none' }}>
        <div className='app__custom-select__list-items' style={{ top: listDown ? '0' : '-350px' }}>
          {options.map((option, index) => (
            <li className='unselectable' onClick={selectItem(option)} key={index}>
              {option}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
