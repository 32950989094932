import React from 'react';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, bId, title, filename, file, saveSameTitle }) => {
  let formData = new FormData();
  formData.set('hID', 'uploadAttachment');
  formData.set('bID', bId);
  formData.set('uID', uId);
  formData.set('title', title);
  formData.set('fileName', filename);
  formData.set('file', file);
  formData.set('checkTitle', !saveSameTitle);
  return formData;
};

export default function useUploadAttachment() {
  const { loading, handlePost } = useApiPost();

  const uploadAttachment = React.useCallback(
    ({ uId, bId, title, filename, file, saveSameTitle, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, title, filename, file, saveSameTitle }),
        ...other
      });
    },
    [handlePost]
  );

  return { uploadAttachment, loading };
}
