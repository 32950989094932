import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from '../api/useApiPost';
import { sortAscending } from '../utils/helpers';

const buildFormData = ({ uId }) => {
    let formData = new FormData();
    const repId = document.getElementById('all_rep_emails').value;
    formData.set('hID', 'getVideos');
    formData.set('uID', repId);
    return formData;
};

const mapResponseToData = response => response?.data ?? [];

export default function useRepVideos() {
    const { id: uId } = useUser();

    const { data, handlePost, loading, error } = useApiPost({ mapResponseToData });

    const handleRefresh = React.useCallback(
        ({ onSuccess, onError }) => handlePost({ onSuccess, onError, data: buildFormData({ uId }) }),
        [uId, handlePost]
    );

    return { videos: data, loading, error, handleRefresh };
}
