import * as React from 'react';
import Routes from './components/router/routes';
import { MemoryRouter as Router } from 'react-router-dom';
import Layout from './components/layout/Layout';
import AuthGate from './components/layout/AuthGate';
import './App.scss';

export default function App() {
  return (
    <Router>
      <AuthGate>
        <Layout>
          <Routes />
        </Layout>
      </AuthGate>
    </Router>
  );
}
