import React from 'react';
import useUpdateLayout from './useUpdateLayout';

// Each contact can have 1 selected email, which corresponds to the email drop down selector
export default function useSelectEmail() {
  const updateLayout = useUpdateLayout();
  return React.useCallback(
    ({ contactId, fieldName }) => {
      updateLayout(prev => ({
        ...prev,
        selectedEmails: {
          ...prev.selectedEmails,
          [contactId]: fieldName
        }
      }))
    },
    [updateLayout]
  );
}
