import React from 'react';
import AttachmentGallery from './AttachmentGallery';
import AttachmentUpload from './AttachmentUpload';
import useUpdateSelectedVideo from '../store/actions/useUpdateSelectedVideo';
import useUpdateScriptOptions from '../store/actions/useUpdateScriptOptions'
import useUpdateSelectedPrograms from '../store/actions/useUpdateSelectedPrograms';
import useUpdateSelectedDocuments from '../store/actions/useUpdateSelectedDocuments';
import useUpdateSelectedImages from '../store/actions/useUpdateSelectedImages';
import { defaultScriptDetails } from '../store/reducers/layout';
import { isActiveFeature } from '../utils/helpers.js';

export default function AllAttachments({ canSelect = false, canDelete = false, canUpload = true }) {
  const [{ activeTab }, setState] = React.useState({
    activeTab: 'gallery'
  });
  const updateSelectedVideo = useUpdateSelectedVideo();
  const updateScriptOptions = useUpdateScriptOptions();
  const updateSelectedDocuments = useUpdateSelectedDocuments();
  const updateSelectedPrograms = useUpdateSelectedPrograms();
  const updateSelectedImages = useUpdateSelectedImages();

  React.useEffect(() => {
    if(!canSelect) {
      updateSelectedVideo(null);
      updateSelectedDocuments([]);
      updateSelectedPrograms([]);
      updateSelectedImages([]);
      updateScriptOptions(defaultScriptDetails);
    }
  }, []);

  const tabClick = React.useCallback(tabName => () => setState(prev => ({ ...prev, activeTab: tabName })), [setState]);

  return (
    <div className='app__all-attachments'>
      {canUpload && isActiveFeature('custom_library') &&
        <div className="app__subheader__tabs">
          <button className={activeTab !== 'upload' ? 'subheader__tab active' : 'subheader__tab'} onClick={tabClick('gallery')}>Gallery</button>
          <button className={activeTab === 'upload' ? 'subheader__tab active' : 'subheader__tab'} onClick={tabClick('upload')}>Upload</button>
        </div>
      }
      {activeTab !== 'upload' &&
        <AttachmentGallery canSelect={canSelect} canDelete={canDelete} />
      }
      {activeTab === 'upload' &&
        <AttachmentUpload />
      }
    </div>
  );
}
