import React from 'react';
import useInterval from './useInterval';

const interval = 1000; // 1 second interval

export default function useTimer() {
  const [{ seconds, isRunning }, setState] = React.useState({ seconds: 0, isRunning: false });

  const callBack = React.useCallback(() => {
    setState(prev => ({ ...prev, seconds: prev.seconds + 1 }));
  }, [setState]);

  useInterval(
    callBack,
    isRunning ? interval : null,
    isRunning
  );

  const start = React.useCallback(() => setState({ seconds: 0, isRunning: true }), [setState]);
  const stop = React.useCallback(() => setState(prev => ({ ...prev, isRunning: false })), [setState]);

  return {
    seconds,
    start,
    stop,
    isRunning
  };
}
