import React from 'react';
import { useDispatch } from 'react-redux';
import { updateSelectedContacts } from '.';

export default function useUpdateSelectedContacts() {
  const dispatch = useDispatch();
  return React.useCallback(payload => dispatch(updateSelectedContacts(payload)), [dispatch]);
}


