import React from 'react';
import useApiPost from './useApiPost';
import { isActiveFeature } from '../utils/helpers.js';
import useSelectedTemplateId from '../store/selectors/useSelectedTemplateId';

const buildFormData = ({ uId, bId, templateId, selectedVideo, selectedContacts, selectedDetails, selectedPrograms, selectedDocuments, selectedImages }) => {
  let formData = new FormData();
  formData.set('hID', 'sendEmailTemplate');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('vID', selectedVideo);
  formData.set('tID', templateId);
  formData.set('type', selectedDetails.type);
  formData.set('recipients', JSON.stringify(selectedContacts));
  formData.set('details', JSON.stringify(selectedDetails));
  formData.set('programs', JSON.stringify(selectedPrograms));
  formData.set('files', JSON.stringify(selectedDocuments));
  formData.set('images', JSON.stringify(selectedImages));
  formData.set('smsEnabled', isActiveFeature('sms_sends') ? 1 : 0);
  return formData;
};

export default function useSendEmail() {
  const { loading, handlePost } = useApiPost();
  const [{ disableSend }, setState] = React.useState({ disableSend: false });
  const templateId = useSelectedTemplateId();

  const sendEmail = React.useCallback(
    ({ uId, bId, selectedVideo, selectedContacts, selectedDetails, selectedPrograms, selectedDocuments, selectedImages, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, templateId, selectedVideo, selectedContacts, selectedDetails, selectedPrograms, selectedDocuments, selectedImages }),
        onError: () => {
          console.error('Error sending email');
          setState(prev => ({ ...prev, disableSend: true }));
        },
        ...other
      });
    },
    [handlePost, templateId]
  );

  return { sendingEmail: loading, sendEmail, disableSend };
}
