import React from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '.';

export default function useLoginUser() {
  const dispatch = useDispatch();
  return React.useCallback(
    payload => {
      // dispatch(push(pathname)); // This clears any search/query params and reloads the previous route on refresh
      dispatch(loginUser(payload));
    },
    [dispatch]
  );
}
