import React, { Component } from "react";
import ReactPlayer from "react-player";

class VideoModal extends Component {
  render() {
    return (
      <div className="app__modal app__modal--video-preview" id="email-sent-modal">
        <div className="app__modal__container">
        <h5>{this.props.title}</h5>
          <button className="app__modal__close--video-preview" onClick={this.props.handleClose}>
            <i className="fa fa-close"></i>
          </button>
          <ReactPlayer url={this.props.path} playing controls />
        </div>
      </div>
    );
  }
}

export default VideoModal;
