import React from 'react';
import { isSafari } from 'react-device-detect';

const SafariInstructions = () => (
  <>
    <br />
    To enable the MediaRecorder API for Safari:
    <br />
    <br />
    Go to Safari → Preferences → Advanced.
    <br />
    Enable the option to “Show Develop menu in menu bar” at the bottom.
    <br />
    Go to Develop → Experimental Features.
    <br />
    Enable MediaRecorder.
    <br />
  </>
);

export default function MediaRecorderGate({ onBack, children }) {
  return window.MediaRecorder ? (
    children
  ) : (
    <>
      <br />
      MediaRecorder is not supported in this browser.
      <br />
      <br />
      Please ensure your hardware is connected and your browser's MediaRecorder API is enabled.
      <br />
      <br />
      To check your browsers compatability with MediaRecorder please refer to: <a href='https://caniuse.com/mediarecorder'>https://caniuse.com/mediarecorder</a>
      <br />
      <br />
      {isSafari && <SafariInstructions />}
      <br />
      <div className={`app__pagenavigation__icon prev`} onClick={onBack}>
        <i className='fa fa-caret-left'></i> BACK
      </div>
    </>
  );
}
